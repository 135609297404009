import React from 'react';
import Layout from '../components/Layout';
import BreadcrumbSchema from '../components/BreadcrumbSchema';
import Breadcrumbs from '../components/Breadcrumbs';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';

const News = ({ data }) => {
  const posts = data.allContentfulNews.nodes;
  const title =
    '当事務所からのお知らせ｜奈良のホームページ制作なら｜インヴォルブ';
  const description =
    '当事務所からの 重要なお知らせや休業情報 を掲載しています。大型連休の営業スケジュールや、皆様に知っておいていただきたい最新情報 を随時更新しております。ぜひご確認ください。';
  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    {
      name: '当事務所からのお知らせ',
      url: 'https://www.involve-in.jp/news',
    },
  ];

  return (
    <Layout>
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.involve-in.jp/news/" />
        <link
          rel="icon"
          type="image/x-icon"
          href="/images/common/favicon.ico"
        />

        {/* Open Graph (OGP: Facebook, LinkedIn) */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.involve-in.jp/news/" />
        <meta
          property="og:image"
          content="https://www.involve-in.jp/images/common/ogp.png"
        />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content="https://www.involve-in.jp/images/common/ogp.png"
        />
      </Helmet>
      <section className="max-w-screen-xl w-full mx-auto py-32 px-6 bg-white bg-opacity-50 shadow-lg rounded-lg">
        <h1 className="font-roboto text-6xl md:text-[10rem] font-bold text-center mb-12">
          News
        </h1>
        <h2 className="text-3xl font-bold mb-6 text-center">新着情報</h2>
        <ul className="divide-y divide-gray-300">
          {posts.map((post) => (
            <li key={post.slug} className="py-6 pl-12 md:pl-20">
              <Link to={`/news/${post.slug}/`} className="flex items-center">
                <span className="text-xl md:text-2xl text-gray-500">
                  {post.publishDate}
                </span>
                <span className="text-xl md:text-2xl font-semibold text-black hover:text-blue-600 transition ml-6 md:ml-12">
                  {post.title}
                </span>
              </Link>
            </li>
          ))}
        </ul>
      </section>
      <Breadcrumbs breadcrumbList={breadcrumbList} />
    </Layout>
  );
};

export const query = graphql`
  query NewsIndex {
    allContentfulNews(sort: { publishDate: DESC }) {
      nodes {
        title
        slug
        publishDate(formatString: "YYYY年MM月DD日")
      }
    }
  }
`;

export default News;
